// store.js
import {h, reactive, ref } from 'vue'
import axios from 'axios'
import { ElMessageBox,ElCheckbox,ElSwitch  } from 'element-plus';

export const account = reactive({})
export const isLoggedIn = ref(false)
export const infos = reactive({})
export const hostname=window.location.hostname;
export const isHide=(hostname=='www.chatgpt-claude.asia'||hostname=='chatgpt-claude.asia')

const isChecked = ref(false);

export function setAccount(data) {
  Object.assign(account, data)
}

export function clearAccount() {
  Object.keys(account).forEach(key => delete account[key])
}


export function autoLogin() {
  return new Promise((resolve, reject) => {
    const loginToken = localStorage.getItem('loginToken');
    if (loginToken) {
      axios.post('/job/autoLogin', { 'loginToken': loginToken })
        .then(response => {
          if (response.data.status === 200) {
            Object.assign(account, response.data); // 将整个响应数据赋值给account
            isLoggedIn.value = true;
          } else {
            isLoggedIn.value = false;
          }
          resolve(); // 无论是否成功登录，都解析Promise
        })
        .catch(error => {
          console.log(error);
          isLoggedIn.value = false;
          resolve(); // 捕获错误，解析Promise以继续导航
        });
    } else {
      resolve(); // 没有token时，直接解析Promise以继续导航
    }
  });
}

// 获取通知数据
export function fetchInfos() {
  axios.get('/job/info', {})
    .then(response => {
      Object.assign(infos, response.data);
      if (infos.alert) {
        // 检查是否已经设置了今日不再弹出
        const dontShowDate = localStorage.getItem('dontShowAlertToday');
        const today = new Date().toISOString().slice(0, 10); // 格式为 YYYY-MM-DD
        if (dontShowDate !== today) {
          showAlertMessage(infos.alert); // 调用函数显示弹窗
        }
      }
    })
    .catch(error => {
      console.log(error);
    });
}

const showAlertMessage = (alertMessage) => {
  const dontShowToday = ref(false); // 用于跟踪“今日不再弹出”的状态

  ElMessageBox({
    title: '通知',
    message: () => h('div', [
      h('div', {
        innerHTML: alertMessage // 使用 innerHTML 渲染 HTML 内容
      }),
      h(ElCheckbox, {
        modelValue: dontShowToday.value,
        'onUpdate:modelValue': (val) => {
          dontShowToday.value = val;
        }
      }, '今日不再弹出')
    ]),
    beforeClose: (action, instance, done) => {
      if (dontShowToday.value) {
        const today = new Date().toISOString().slice(0, 10); // 格式为 YYYY-MM-DD
        localStorage.setItem('dontShowAlertToday', today); // 存储今天的日期
      }
      done();
    }
  });
}